<template>
  <v-container style="max-width:2000px;background-color:#F2F2F2;">
    <v-row justify="start">
      <v-col
        cols="12"
        xs="12" sm="12"
        md="12" lg="12" xl="12"
        class="px-2 py-2"
        :class="{ 'pa-2': $vuetify.breakpoint.smAndDown }"
      >
        <v-card>
          <v-card-title
            class="py-1 px-4 pl-0"
            style="background-color:transparent;color:#AD1457;border-bottom: 2px solid #AD1457;"
          >
            <v-btn icon color="#AD1457" @click="goBack" class="mr-1">
              <v-icon style="font-size:32px;">mdi-chevron-left</v-icon>
            </v-btn>
            Một lượt đi quay
            <v-spacer />
            <v-btn v-if="alllowCancel"
              class="text-none"
              dark color="red"
              @click="callCancelSession"
              style="height:32px;"
            > Hủy thuê </v-btn>

            <v-btn v-if="allowApprove"
              class="text-none ml-2"
              dark color="teal"
              @click="callApproveSession"
              style="height:32px;"
            > Phê Duyệt </v-btn>

            <v-btn v-if="allowReject"
              class="text-none ml-2"
              dark color="red"
              @click="callRejectSession"
              style="height:32px;"
            > Từ chối </v-btn>
          </v-card-title>

          <div v-if="errorMessage" style="color:red;" class="py-1">
            {{ errorMessage }}
          </div>

          <v-card-text
            v-if="session"
            class="py-2 pt-2"
            :class="{ 'px-2': $vuetify.breakpoint.smAndDown }"
          >
            <!-- Select a Room -->
            <div class="pt-1">
              <div class="" style="display:inline-block;min-width:100px;">
                Phim trường:
              </div>
              <div
                style="display:inline-block;min-width: 200px;font-size:16px;"
              >
                <b>{{ session.properties.map((p) => p.name).join(", ") }}</b>
              </div>
            </div>

            <!-- Program -->
            <div class="pt-1">
              <div class="" style="display:inline-block;min-width:100px;">
                Chương trình:
              </div>
              <div
                style="display:inline-block;min-width: 200px;font-size:16px;"
              >
                <b>{{ session.program?session.program.name:''}}</b>
              </div>
            </div>

            <!-- Select A Time Range -->
            <div class="pt-1">
              <!-- startDate -->
              <div
                class="mr-4"
                :class="{ 'rent-inline-block': $vuetify.breakpoint.mdAndUp }"
              >
                <div style="display:inline-block;min-width:100px;">
                  Bắt đầu:</div>
                <div style="display:inline-block;">
                  <b>{{
                    $moment(session.start_time).format("HH:mm, DD/MM/YYYY")
                  }}</b>
                </div>
              </div>
              <!-- endDate -->
              <div
                class="py-1 pb-0"
                :class="{ 'rent-inline-block': $vuetify.breakpoint.mdAndUp }"
              >
                <div style="display:inline-block;min-width:100px;">
                  Kết thúc:
                </div>
                <div style="display:inline-block;">
                  <b>{{
                    $moment(session.end_time).format("HH:mm, DD/MM/YYYY")
                  }}</b>
                </div>
              </div>

              <!-- Số giờ thuê -->
              <div class="py-1 pt-0">
                <div style="display:inline-block;min-width:100px;">
                  Số giờ thuê:
                </div>
                <div style="display:inline-block;font-size:16px;">
                  <b>{{ numOfHours }}</b>
                </div>
              </div>
            </div>

            <!-- Người thuê -->
            <div class="pt-1">
              <div style="display:inline-block;min-width:100px;">
                Người thuê:
              </div>
              <div style="display:inline-block;font-size:16px;">
                <b>{{ session.fullname }}</b>
              </div>
            </div>

            <!-- Trạng thái -->
            <div class="pt-1">
              <div style="display:inline-block;min-width:100px;">
                Trạng thái:
              </div>
              <div style="display:inline-block;font-size:16px;">
                <v-chip dark small :color="getStatusColor(session.status)">
                  <b>{{ getStatusName(session.status) }}</b>
                </v-chip>
              </div>
            </div>

            <div class="pt-1" v-if="session.status == 'CANCELLED'">
              <div style="display:inline-block;min-width:100px;">Ngày hủy:</div>
              <div style="display:inline-block;font-size:14px;">
                <b>{{
                  $moment(session.cancelled_at).format("HH:mm, DD/MM/YYYY")
                }}</b>
              </div>
            </div>

            <div class="pt-1" v-if="session.status == 'APPROVED'">
              <div style="display:inline-block;min-width:100px;">
                Ngày duyệt:
              </div>
              <div style="display:inline-block;font-size:14px;">
                <b>{{
                  $moment(session.confirmed_at).format("HH:mm, DD/MM/YYYY")
                }}</b>
              </div>
            </div>

            <div class="pt-1" v-if="session.status == 'REJECTED'">
              <div style="display:inline-block;min-width:100px;">
                Ngày từ chối:
              </div>
              <div style="display:inline-block;font-size:14px;">
                <b>{{
                  $moment(session.rejected_at).format("HH:mm, DD/MM/YYYY")
                }}</b>
              </div>
            </div>

            <!-- Rejected Reason -->
            <div v-if="session.status == 'REJECTED'" class="mt-1">
              <div style="display:inline-block;min-width:100px;">
                Lý do từ chối:
              </div>
              <div style="display:inline-block;font-size:16px;">
                <p v-html="session.rejected_reason"></p>
              </div>
            </div>

            <!-- Description -->
            <div class="pt-1">
              <div style="display:inline-block;min-width:100px;">Ghi chú:</div>
              <div style="display:inline-block;font-size:16px;">
                <p v-html="session.description"></p>
              </div>
            </div>
          </v-card-text>

          <v-card-actions class="px-4">
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- khách mời -->
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
        class="px-2 py-2"
        :class="{ 'pa-2': $vuetify.breakpoint.smAndDown }">
        <v-card>
          <v-card-title class="py-0"
            style="background-color:#AD145799;color:white;font-size:16px;">
            Nhân sự tham gia
          </v-card-title>
          <v-card-text class="py-2" style="min-height:60px;">
            <div>
              <ul v-if="session && session.inviters_name && session.inviters_name.length>0">
                <li v-for="(inviter, index) in session.inviters_name"
                  :key="index"
                  style="font-weight:500;color:#888888;">
                  {{inviter}}
                </li>
              </ul>
              <div v-else style="color:#CCCCCC;">
                <i>Chưa có</i>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- thuê thiết bị -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"
        class="px-2 py-2"
        :class="{ 'pa-2': $vuetify.breakpoint.smAndDown }">
        <v-card>
          <v-card-title class="py-0"
            style="background-color:#AD145799;color:white;font-size:16px;">
            Thiết bị
          </v-card-title>
          <v-card-text class="py-2" style="min-height:200px;">
            <div>
              <div style="font-weight:700;color:#666666;font-size:13px;border-bottom:1px solid #CCCCCC;">
                Thiết bị đăng ký
              </div>
              <ul v-if="session && session.assets_booking">
                <li v-for="(asset, index) in session.assets_booking"
                  :key="index"
                  style="font-weight:500;color:#888888;">
                  {{asset}}
                </li>
              </ul>
            </div>

            <div class="mt-2">
              <div class="d-flex"
                style="font-weight:700;color:#666666;font-size:13px;border-bottom:1px solid #CCCCCC;">
                Thiết bị được cấp
                <v-spacer/>
                <v-btn style="height:20px;"
                  @click="dialogPickEquiment=true"
                  text color="primary"
                  class="text-none">
                  Cấp thiết bị
                </v-btn>
              </div>

              <v-dialog v-model="dialogPickEquiment"
                width="640px">
                <panel-pick-equipment v-if="dialogPickEquiment" @picked="onEquimentPicked"/>
              </v-dialog>

              <div class="mt-2">
                <div v-for="(lend, eIndex) in assetLends" :key="eIndex"
                  class="pl-2 pr-1 mt-2"
                  style="background-color:#EEEEEE;border-radius: 0 6px 6px 0;"
                  :style="{'border-left': lend.status=='LEND'?'8px solid orange': '8px solid green'}">
                  <div>
                    <div class="mr-2" 
                      @click="gotoPath('/equipts-detail/'+ lend.property._id)"
                      style="display:inline-block;vertical-align:top;min-width:90px;max-width:90px;font-size:12px;color:black;">
                      <b>{{lend.property?lend.property.qr_code:''}}</b>
                    </div>
                    <div class="mr-2" 
                      @click="gotoPath('/equipts-detail/'+ lend.property._id)"
                      style="display:inline-block;vertical-align:top;color:teal;font-weight:500;cursor:pointer;font-size:15px;">
                      {{lend.property?lend.property.name:''}}
                    </div>
                  </div>
                  <div style="color:#222222;font-size:13px;line-height:26px;">
                    <div
                      style="display:inline-block;vertical-align:top;min-width:190px;max-width:190px">
                      <span>Cấp lúc: {{$moment(lend.lend_at).format('DD/MM/YYYY, HH:mm')}}</span>
                    </div>
                    <div 
                      style="display:inline-block;vertical-align:top;">
                      <span v-if="lend.return_at">Trả lúc: {{$moment(lend.return_at).format('DD/MM/YYYY, HH:mm')}}</span>
                      <span v-else>Chưa trả</span>
                    </div>
                    <div v-if="lend.status=='LEND'"
                      style="display:inline-block;vertical-align:top;float:right;">
                      <v-btn text color="primary"
                        class="text-none px-2"
                        @click="lendReturn(lend)"
                        style="height:24px;vertical-align:top;">
                        Hoàn trả
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- thuê xe -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"
        class="px-2 py-2"
        :class="{ 'pa-2': $vuetify.breakpoint.smAndDown }">
        <v-card>
          <v-card-title class="py-0"
            style="background-color:#AD145799;color:white;font-size:16px;">
            Thuê xe
          </v-card-title>
          <v-card-text class="py-2" style="min-height:200px;">
            <div class="py-1">
              <div class="" style="display:inline-block;min-width:100px;">
                Xe đăng ký:
              </div>
              <div
                style="display:inline-block;min-width: 200px;font-size:16px;"
              >
                <b v-if="session && session.cars && session.cars.length >0">{{ session.cars.map((p) => p.name).join(", ") }}</b>
                <i v-else>Không đăng ký xe</i>
              </div>
            </div>
            <div class="py-1 mt-2" v-if="1==2">
              <div class="" style="display:inline-block;min-width:100px;">
                Xe được cấp:
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      userId: "userId",
      token: "token",
      role: "role",
      fullname: "getFullName",
    }),
    isAdminMode: function() {
      return this.$root.nhanSuRoleUser.includes(this.userId);
    },
    isMyRentSession: function() {
      if (this.session && this.session.user_id == this.userId) {
        return true;
      }
      return false;
    },
    alllowCancel: function() {
      if (
        this.isMyRentSession &&
        new Date(this.session.start_time).getTime() > new Date().getTime() &&
        ["WAIT_FOR_APPROVE", "APPROVED"].includes(this.session.status)
      ) {
        return true;
      }
      return false;
    },
    allowApprove: function() {
      if (
        !this.isMyRentSession &&
        this.isAdminMode &&
        this.session &&
        new Date(this.session.start_time).getTime() > new Date().getTime() &&
        ["WAIT_FOR_APPROVE"].includes(this.session.status)
      ) {
        return true;
      }
      return false;
    },
    allowReject: function() {
      if (
        !this.isMyRentSession &&
        this.isAdminMode &&
        this.session &&
        new Date(this.session.start_time).getTime() > new Date().getTime() &&
        ["WAIT_FOR_APPROVE", "APPROVED"].includes(this.session.status)
      ) {
        return true;
      }
      return false;
    },
    numOfHours: function() {
      if (this.session) {
        return (
          this.$moment(this.session.end_time).diff(
            this.$moment(this.session.start_time),
            "minutes"
          ) / 60
        );
      }
      return 0;
    },
  },
  data() {
    return {
      session: null,
      users: [],
      dialogPickEquiment: false,
      assetLends: [],
      errorMessage: "",
      statusList: [
        {
          selected: true,
          code: "WAIT_FOR_APPROVE",
          label: "Chờ duyệt",
          color: "orange",
        },
        { selected: true, code: "APPROVED", label: "Đã duyệt", color: "green" },
        {
          selected: false,
          code: "REJECTED",
          label: "Đã từ chối",
          color: "red",
        },
        {
          selected: false,
          code: "CANCELLED",
          label: "Đã hủy",
          color: "#888888",
        },
      ],
    };
  },
  watch: {
    '$route.params.session_id': async function () {
      this.fetchRentSession()
      await this.fetchEquipRentForThisSession(this.$route.params.session_id)
    },
    users () {
      this.processAfterFetchData();
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    gotoPath(path) {
      this.$router.push({path: path})
    },
    getStatusName(code) {
      let matchStatus = this.statusList.find((status) => status.code == code);
      if (matchStatus) return matchStatus.label;
      return "";
    },
    getStatusColor(code) {
      let matchStatus = this.statusList.find((status) => status.code == code);
      if (matchStatus) return matchStatus.color;
      return "black";
    },
    processAfterFetchData() {
      if(!this.users || !this.session) return
      if (this.users.length > 0) {
        // Set Fullname
        if (this.session.user_id == this.userId) {
          this.session.fullname = this.fullname;
        } else {
          let user = this.users.find((u) => u._id == this.session.user_id);
          if (!user) this.session.fullname = "";
          this.session.fullname = user.fullname;
        }

        // Set Inviter Name
        this.session.inviters_name = this.users
          .filter((u) => this.session.inviters_id.includes(u._id))
          .map((u) => u.fullname);
      }
      this.$forceUpdate();
    },
    async onEquimentPicked (equips) {
      this.dialogPickEquiment = false
      if(!equips || equips.length<=0) return
      try {
        for (let i=0; i < equips.length; i++) {
          await this.createEquiqLendSession(equips[i])
        }
      } catch (err) {
        alert("Lỗi! " + err)
      }

      try {
        await this.fetchEquipRentForThisSession(this.session_id)
      } catch (err) {
        alert('Lỗi! ' + err)
      }
      
    },
    async createEquiqLendSession (equip) {
      let assetLend = {
        code: new Date().getTime,
        type: 'LEND-ASSET',
        program: '',
        session_id: this.session._id,
        property: equip._id,
        user_id: this.session.user_id,
        give_note: '',
        images: [],
        props: [],
        lend_at: new Date(),
        lend_by: this.userId,
      }

      let res = await this.axios
        .post(this.$root.apiAssetMana + "/assetLends",
          assetLend, {
          headers: { "x-auth": this.token },
        })
      if (res.data.status == "OK") {
        await this.fetchEquipRentForThisSession(this.session._id)
      }
      if (res.data.status != "OK") {
        alert("Lỗi: " + res.data.message)
      }
    },
    async fetchEquipRentForThisSession(sessionId) {
      if(!sessionId) return
      let res = await this.axios.get(this.$root.apiAssetMana + `/assetLends?types=LEND-ASSET&&session_ids=${sessionId}`)
      if (res.data.status == "OK") {
        this.assetLends = res.data.content
        this.$forceUpdate()
      }
    },
    async lendReturn (lend) {
      if(!lend) return
      try {
        let res = await this.axios.put(this.$root.apiAssetMana + `/assetLends/return/${lend._id}`)
        if (res.data.status == "OK") {
          await this.fetchEquipRentForThisSession(this.session._id)
        } else {
          alert('Lỗi! ' + res.data.message)
        }
      } catch(err) {
        alert('Lỗi! ' + err)
      }
    },
    fetchRentSession() {
      if (!this.$route.params.session_id) {
        return;
      }
      let sessionId = this.$route.params.session_id;
      let self = this;

      this.axios
        .get(self.$root.apiAssetMana + `/sessions/${sessionId}`, {
          headers: { "x-auth": this.token }    
        })
        .then((res) => {
          if (res.data.status == "OK") {
            self.session = res.data.content;
            console.log(self.session);

            self.processAfterFetchData();
          } else {
            self.errorMessage = res.data.message;
          }
        })
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
    },
    async fetchUsers() {
      try {
        let res = await this.axios.get(
          this.$root.apiUser + "/users/list/" + this.$root.apiUserCustomId,
          {
            params: { showEnable: "true" },
          }
        );
        if (res.data.status == "OK") {
          this.users = res.data.content.items;
        }
      } catch (err) {
        console.log(err);
      }
    },
    callCancelSession() {
      if (!confirm("Xác nhận hủy đặt phim trường?")) return;
      let self = this;
      this.axios
        .put(
          self.$root.apiAssetMana + "/sessions/locks",
          {
            ids: [this.session._id],
          },
          { headers: { "x-auth": this.token } }
        )
        .then((res) => {
          if (res.data.status == "OK") {
            alert("Hủy thành công!");
            self.fetchRentSession();
          } else {
            alert("Lỗi! " + res.data.message);
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          alert("Lỗi! " + err.mesage);
          console.log(err);
        });
    },
    callApproveSession() {
      let self = this;
      let payload = {
        confirmedBy: this.userId,
        ids: [this.session._id],
      };
      this.axios
        .put(self.$root.apiAssetMana + "/sessions/approves", payload, {
          headers: { "x-auth": this.token },
        })
        .then((res) => {
          if (res.data.status == "OK") {
            self.fetchRentSession();
          } else {
            alert("Lỗi! " + res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Lỗi! " + err.message);
        });
    },
    callRejectSession() {
      let self = this;
      let payload = {
        rejectedBy: this.userId,
        ids: [this.session._id],
      };
      this.axios
        .put(self.$root.apiAssetMana + "/sessions/rejects", payload, {
          headers: { "x-auth": this.token },
        })
        .then((res) => {
          if (res.data.status == "OK") {
            self.fetchRentSession();
          } else {
            alert("Lỗi! " + res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Lỗi! " + err.message);
        });
    },
  },
  created () {
    this.$nextTick(async () => {
      if (this.$route.params.session_id) {
        this.fetchRentSession()
        await this.fetchEquipRentForThisSession(this.$route.params.session_id)
        this.fetchUsers()
      }
    });
  },
};
</script>

<style>
.rent-inline-block {
  display: inline-block;
}
</style>
